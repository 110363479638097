import React from "react";
import N5LogoTitle from "@molecules/N5LogoTitle";
import styles from "./our-team.module.scss";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

const OurTeam = () => {
  const { t } = useTranslation();    
  return (
    <section className={`${styles.section} text-center`}>
      <N5LogoTitle title={t("WORK.TITLE")} description={t("WORK.SUBTITLE")} />
      <Link style={{ color: "inherit" }} to='/work#apply'>
        <div className='btnprimary'>{t("WORK.APPLYNOW")}</div>
      </Link>
    </section>
  );
};

export default OurTeam;
