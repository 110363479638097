import React from "react";
import styles from "./n5-logo-title.module.scss";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import logo from "../../../assets/image/n5_logo_transparent.png";

const N5LogoTitle = ({ title, description }) => {  
  return (
    <>
      <motion.figure
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: "spring", stiffness: 20 }}
        className={styles.logo}
      >        
        <img
          src={logo}
          style={{
            maxWidth: 426,
            width: "100%",
          }}
          alt='N5 Logo'
          loading="lazy"
        />
      </motion.figure>
      <motion.h1
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: "spring", stiffness: 20 }}
        className={styles.title}
      >
        {title}
      </motion.h1>
      <motion.p
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ type: "spring", stiffness: 20 }}
        className={styles.description}
      >
        {description}
      </motion.p>
    </>
  );
};

N5LogoTitle.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default N5LogoTitle;
